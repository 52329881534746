import styled from 'styled-components';

export const BudgetCardItem  = styled.div`
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    /* background: tan; */
    width: 250px;
    height: 100px;
    margin: 5px;
    display:flex;
    flex-direction:row;
`;
export const SpLeftLine = styled.div`
    height:100%;
    width:.1%;
    background:#06e; 
`;
export const LeftLine = styled.div`
    height:100%;
    width:1%;
    background:#06e;
`;
export const RightCardContent = styled.div`
    width:99%;
    height:100%;
    display:flex;
    flex-direction:column;
`;

export const CategorySelector = styled.select`
    width: 93%;
    height: 38px;
    padding-left: 5px;
    font-size: 14px;
    background: #e9eefb;
    border: none;
    border-radius: 0.25rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
`;

export const CardTitle = styled.span`
    width:100%;
    font-weight:500;
    font-size:14px;
    margin:5px;
`;

export const CardBody  = styled.div`
    width:100%;
    height:100%;
    display:flex;
`;
export const SpPackagePrice = styled.span`
    width: 100%;
    font-size: 14px;
    text-align: end;
`;
export const CardPrice = styled.span`
    /* text-overflow: ellipsis; */
    max-width: 100px;
    /* background: red; */
    font-size: 14px;
    
    
`;

export const CardDesc = styled.span`
    font-weight:100;
    font-size:10px;
    max-height:100%;
    max-width:100%;
    word-wrap: break-word;
`;

export const CardChartContainer = styled.div`
 height:100%;
 width:50%;
 justify-content:center;
 align-items:center;
 display:flex;
 /* background:red; */
`;

export const SpCardTextContainer = styled.div`
    width:100%;
    height:100%;
    margin: 2px;
    display:flex;
    flex-direction:column;
    max-height:100%;

`;

export const CardTextContainer = styled.div`
    width:50%;
    height:100%;
    margin: 2px;
    display:flex;
    flex-direction:column;
    max-height:100%;

`;