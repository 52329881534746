import React from 'react'
import { StatsAmount, StatsContainer, StatsTitle } from './StatsItem.elements'
type statsOptions = {
    title:string;
    amount:string;
}
const StatsItem:React.FC<statsOptions>=({title,amount})=> {
    return (
        <StatsContainer>
            <StatsTitle>
                {title}
            </StatsTitle>

            <StatsAmount>
                {amount}
            </StatsAmount>

        </StatsContainer>
    )
}

export default StatsItem
