import styled from 'styled-components';



export const CardHolder = styled.div`
  transition: 0.3s;
  /* width: 23%; */
  flex: 0 0 350px;
  position: relative;
  flex-direction: row;
  margin:10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  /* max-width: 400px; */

  /* width: 350px; */
  /* height: 432px; */

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
    cursor: pointer;
  }

  @media (max-width: 800px) {
  flex-direction:column;
}


`;


export const Badge = styled.p`
  height: auto;
  background:#4A85FF;
  position: absolute;
  top: 0px;
  right: 0px;
  color:white;
  border-radius:5px 0px 0px 5px;
  padding:3px;
`;

export const CardImage = styled.img`
width: 100%;
height: 350px;
left: 0px;
top: 0px;
    border-radius: 5px 5px 5px 5px;
    object-fit: cover;
`;


export const EventTitle = styled.p`
    font-size:20px;
    /* font-weight:600; */
    margin:0;
    font-style:normal;
    color:#18191F;
    /* font-family: 'Playfair Display', serif; */
    font-feature-settings: 'liga' off;
    
`;

export const EventDate = styled.p`
    font-size:16px;
    font-weight:normal;
    margin: 5px 0px 0px 2px;
   
`;

export const EventTypeWrapper = styled.div`
`;
export const EventType = styled.p`
`;

export const CardWrapper = styled.div`
    display: flex;
    /* justify-content:space-around; */
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
 
  

    ::-webkit-scrollbar {
    /* display: none; */
    }
`;