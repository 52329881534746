import styled from "styled-components";


type MessageProps = {
    message:string;
    isError?:boolean;
    width?:number;
    height?:number;
    isSuccess?:boolean;
}


type MessageWrapperProps = {
    isError?: boolean;
    isSuccess?: boolean;
}


export const Wrapper = styled.div<MessageWrapperProps>`
    display:flex;
    justify-content:center;
    background:${(props:MessageWrapperProps) => props.isError ? '#fee' : props.isSuccess ? '#50c878' : '#4fc3f7'};
    height:50px;
    width:80%;
    color:${(props:MessageWrapperProps) => props.isError ? '#c33' : props.isSuccess ? '#fff' : '#fff'};;
    align-items:center;
    border: 1px solid ${(props:MessageWrapperProps) => props.isError ? '#f44336' : props.isSuccess ? '#66bb6a' : '#63ccff'};
`;


const GlobalActionMessage:React.FC<MessageProps> = ({message,isError,width,height,isSuccess}) =>{
    return(
            <Wrapper isError = {isError} isSuccess={isSuccess}>
                {message}
            </Wrapper>
    );
}


export default GlobalActionMessage;