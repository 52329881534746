import React, { useState } from 'react'
import img from '../../assets/images/login_bg.png';
import {  SignupHeader } from '../Body/Body.elements';
import SignupForm from '../SignupForm/Signup';
import SpSignupForm from '../SignupForm/SpSignup';


import { FormLinks, MainFormHolder, ModalContent, ModalImg, ModalWrapper, TopNavHolder } from './Modal.elements';

type modelData = {
    isEvent:boolean,
    onClose:()=>void;
}


const Modal:React.FC<modelData> = ({isEvent,onClose})=>{
    const [isUserSignup, setIsUserSignup] = useState(isEvent);


    // function handleOnchange(val:any){
    //     console.log(val);
    // }


    // const changeModalState = (state : boolean) => () => setIsOpen(state);
    // function toggleModal() {
    //     setIsOpen(!isOpen);
    //   }


    const handleUserClick = () =>{
        setIsUserSignup(true);
    }

    const handleSpClick = () =>{
        setIsUserSignup(false);
    }
    return(
        <>
        {
          
    
                    
                    <ModalWrapper>
                   
                            <ModalImg src={img} alt='Wedding' />
                            <ModalContent>
                           <SignupHeader>
                           Get Registered.
                           </SignupHeader>
                                {/* <h1>Are you ready?</h1>
                                <p>Get exclusive access to our next launch.</p>

                                <Spinner title="Event Type" options={["Harusi","Kitchen-Party","Engagement"]}/>

                                <InputField label="name" type="text" onChange={value=>handler(value)} />

                                <InputField label="Date" type="date" onChange={value=>handler(value)} />
                               
                                <button>Join Now</button> */}


                              
                                <MainFormHolder>
                                    <TopNavHolder>
                                        <FormLinks isActive={isUserSignup} onClick={handleUserClick}>Event</FormLinks>
                                        <FormLinks isActive={!isUserSignup}  onClick = {handleSpClick}>Service Provider</FormLinks>
                                    </TopNavHolder>
                                    


                                      {isUserSignup ? <SignupForm /> : <SpSignupForm/>}

                                </MainFormHolder>
                            </ModalContent>
                            {/* <CloseModalButton
                                aria-label='Close modal'
                                onClick={onClose}
                            /> */}
                    </ModalWrapper>
   
            
        }
        </>
    );
}



export default Modal;







