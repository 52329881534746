import React from 'react'
import { SidebarCardContainer,SidebarMenuTitle } from './Sidebaritem.Elements'

type cardItemsOptions = {
    icon: any;
    title: String;
    isActive: boolean;
}



const SidebarItem:React.FC<cardItemsOptions> = ({icon,title,isActive}) => {
    return (
        <SidebarCardContainer>
            {icon}
           
            <SidebarMenuTitle>
            {title}
            </SidebarMenuTitle>
        </SidebarCardContainer>
    )
}

export default SidebarItem
