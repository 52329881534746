import React, { useState } from 'react';
import { ApiClient } from '../../Data/ApiClient';
import { Button } from '../../theme/globalStyles';
import { Loader } from '../Loader/Loader';
import { FormWrapper } from '../SignupForm/Signup.elements';
import { PledgeStep1, PledgeStep2 } from './Forms';






const PledgeForm:React.FC = () => {
    var uid = window.location.host.split(".")[0];
    const [stepCount,setStepCount] = useState(0);
    const [name,setname] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [showLoader, setshowLoader] = useState(false)
    const [isAttending,setIsAttending] = useState(false);
    const [pledgeAmount,setPledgAmount] = useState("");
    const handlePhoneNumberChange = (val:any) =>{setPhone(val)}
    const handleEmailChange = (val:any) =>{setEmail(val)}
    const handleNameChange = (val:any) =>{setname(val)}
    const handleIsAttendingChange = (val:any) =>{setIsAttending(val)}
    const handlePledgeAmountChange = (val:any) =>{setPledgAmount(val)}
    let config = {
        headers: {
            uid: uid
        }
    };
    const SavePledge = ()=>{
        setshowLoader(true)
        ApiClient.post('rsvp',{
            name:name,
            attending: isAttending,
            mchango: pledgeAmount,
            mobile: phone,
            email: email
        },config).then(
            (response)=>{
            alert("Pledge saved")
            window.location.reload();
            }
        ).catch((err)=>{
            console.log("error",err.response.data.errors);
            const error = err.response.data.errors;
            alert(JSON.stringify(error))
        }).finally(()=>{
            setshowLoader(false)
        })
    }


    const handleNextStep = (currentStep:number) => {
        if(currentStep === 0){
            if(name.length > 0 && phone.length === 10){
                setStepCount(cur => cur+1)
                // setIsVerified(!isVerified)
            }else{
                alert("all inputs are required");
                return;
            }
       
         }else if(currentStep === 1){
             if(pledgeAmount.length > 0){
                //done
                SavePledge()
             }else{
                 alert("Pledge amount is required")
             }
         }
    }

    
    const renderBackButtton = () => {
        if(stepCount === 0){
            return undefined;
        }else if(stepCount > 0 && stepCount <= 1){
            return(
                <Button onClick={handleBackStep}>Back</Button>
            );
        }

    };

    const renderNextButton = () => {
        if(stepCount === 0){
            return(
                <Button onClick={e=>handleNextStep(stepCount)}>Next</Button>
            );
        }else if(stepCount > 0 && stepCount <= 1){
            return(
                <Button  onClick={e=>handleNextStep(stepCount)}> Finish</Button>
            );
        }

    };

    const handleBackStep = () => {
        setStepCount(cur => cur-1)

    }

    return(
        <>
        {showLoader ? <Loader size="small" page={false}/>:
        <FormWrapper>

                {stepCount === 0 && (
                    <PledgeStep1 namechangeListener={handleNameChange} phonechangeListener={handlePhoneNumberChange} emailchangeListener={handleEmailChange} nameValue={name} emailValue={email} phoneValue={phone}/>
                )}
                 {stepCount === 1 && (
                    <PledgeStep2 attendingChangeListener={handleIsAttendingChange} isAttending={isAttending} pledgeAmount={pledgeAmount} pledgeAmountChangeListener={handlePledgeAmountChange} />
                )}

                {renderBackButtton()}

                {renderNextButton()}
        </FormWrapper>
}
        </>
    );
}

export default PledgeForm;