import styled from 'styled-components';

export const BudgetSectionContainer = styled.div`
  width: 99%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-gap: 10px;
    justify-items: center;

`;

export const NewBudgetButton = styled.button`
  width: 134px;
  height: 50px;
    background-color: #e9eefb;
    border-radius: 6px;
    border: none;
    font-weight: 200;
    font-size: 14px;
    line-height: 12px;
    padding: 14px 14px;
    color: #06f;
    cursor: pointer;
    margin: 5px;
`;


export const BudgetModalContainer = styled.div`
  background: #fff;
  min-width: 50vh;
  min-height: 50vh;
  max-height: 80vh;
  border-radius: 10px;
  overflow: auto;
`;