import styled from "styled-components";

export const Select = styled.select`
  width: 100%;
  height: 45px;
  background: white;
  /* color: gray; */
  /* height: 6%; */
  padding-left: 5px;
  color: #24366b;
    font-weight: 400;
    font-size: 14px;
  border: 1px solid #e9eef7;
  /* margin-left: 10px; */
  border-radius: 0.25rem;
    display:flex;

  Option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;