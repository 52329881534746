import styled from 'styled-components';
import {CgClose} from 'react-icons/cg';
import { BsPencil } from 'react-icons/bs';
import { FiMail, FiPhone } from 'react-icons/fi';

// export const ModalWrapper = styled.nav`
//       position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;


export const Background = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  /* background: rgba(0, 0, 0, 0.4); */
  /* position: fixed; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  /* @media screen and (max-width: 768px) {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  } */

  
  
`;

export const ModalWrapper = styled.div`
  width: 70%;
  height: 60%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  border-radius: 10px;

  @media only screen and (min-width: 600px) {
    width: 70%;
  height: 60%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index:20;
  /* left: -4vh; */
  display: flex;
  }

  @media screen and (max-width: 768px) {
    /* width: 100px;
    height: 400px; */
    /* display:flex; */
/* 
    background-color: #fff;
  max-width: 400px; */
  width: 90%;
  height: 70%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index:20;
  /* left: -4vh; */
  display: flex;
  /* height: 280px;
  padding: 10px 30px;
  position: absolute;
  left: calc(50% - 200px);
  top: 12%;
  border-radius: 4px;
  z-index: 999;
  pointer-events: auto;
  cursor: auto;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6); */
  }
`;

export const ModalHeader = styled.div`
  text-align:center;
  
`;

export const RsvpModalWrapper = styled.div`
  width: 500px;
  height: 600px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 12;
  border-radius: 10px;
`;

export const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  /* background: #000; */

  @media screen and (max-width: 768px) {
    display:none;
  }

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export const MainFormHolder = styled.div`
    /* background:red; */
    display:flex;
    width:100%;
    /* height:80%; */

    justify-content: space-around;
    align-items: center;
    flex-direction:column;
    

`;


export const TopNavHolder = styled.div`
    height: 50px;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:10px;
`;

type linkProp = {
  isActive?:boolean;
}

export const FormLinks = styled.p<linkProp>`
  color: #1c313a;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.1rem 0.5rem;
  height: 100%;
  font-weight:500;
  /* font-size:20px; */
  background-color:  ${props => (props.isActive ? `#4b59f7` : 'white')};
  color: ${props => (props.isActive ? `white` : 'black')};
  &:hover{
    cursor: pointer;
    /* color: #4b59f7; */
  }

  /* &:active {
  background-color: #666;
  color: white;
  } */

  @media screen and (max-width: 960px) {
    text-align: center;
    /* padding: 2rem; */
    width: 100%;
    /* height: 50%; */
    display: flex;
    justify-content: center;
    &:hover {
      /* color: #4b59f7; */
      transition: all 0.3s ease;
    }
  }
`;

export const ModalContent = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* line-height: 1.8; */
  color: #141414;
  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 10px 24px;
    background: #4B59F7;
    color: #fff;
    border: none;
    margin:10px;
  }
`;

export const CloseModalButton = styled(CgClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;
export const EditModalContainer = styled.div`
  background: #fff;
  max-width: 50vh;
  min-width: 50vh;
  /* min-height: 65vh; */
  /* min-height: 50vh; */
  border-radius: 10px;
`;

export const EditLoaderOverlay = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: ; */
`;

export const ModalContainer = styled.div`
    border-radius: 10px;
    min-width: 80vh;
    min-height: 20vh;
  
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: rgba(0, 0, 0, 0.4); */

    @media screen and (max-width: 768px) {
      min-width: 35vh;
      min-height: 58vh;
    }
  `;

  export const TopSection = styled.div`
  justify-content: space-between;
     width: 100%;
    min-height: 70px;
    border-bottom: 1px solid #dadce0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px;
  `;

export const EditTopSection = styled.div`
justify-content: space-between;
  min-height: 50px;
  border-bottom: 1px solid #dadce0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px;
`;

  export const ContactName = styled.span`
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75rem;
    color: #3c4043;
    word-wrap: break-word;
  `;

  export const ContactEditIcon = styled(BsPencil)`
    cursor: pointer;
    margin: 10px;
  `;

  export const BottomSection = styled.div`
    width: 100%;
    display: flex;
    margin: 5px 5px 5px 10px;
    flex-direction: column;
    padding: 5px;
    /* align-items: center; */
    height:100% ;
    /* background: red; */
  `;
  export const MiddleSection = styled.div`

    /* display: flex; */
    margin: 5px 5px 5px 10px;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;

    /* flex-direction: row; */
    /* background: red; */
  `;

  export const GuestListHeader = styled.span`
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.5rem;
    margin-bottom: 10px;
  `;

export const GuestEditHeader = styled.span`
font-size: 13px;
font-weight: 300;
/* letter-spacing: 0; */
/* line-height: 1.5rem; */
/* margin-bottom: 10px; */
margin-left: 5px;
width: 100%;
display: flex;
justify-content: space-between;
`;

export const GuestEditP = styled.p`
  cursor: pointer;
  margin: 5px;
    &:hover{
        color:#06E;
        }
`;



export const GuestMiddleSectionHeader = styled.span`
font-size: 14px;
font-weight: 300;
letter-spacing: 0;
line-height: 1.5rem;

/* border-bottom: 1px solid #dadce0; */

/* margin-bottom: 10px;
margin-top:10px; */
  /* margin: 10px 0px 10px 0px; */
`;
export const PledgeDescriptionWrapper = styled.textarea`
      width: 100%;
    max-width: 90%;
    margin: 15px;
    margin: 0;
    border: 1px solid #727ea3;
    border-radius: 5px;
    padding: 5px;
    font-weight: 200;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
`;
export const AhadiWrapper = styled.div`
    color: #24366b;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5715;
    text-align: center;
`;

export const GuestListPledgeHeader = styled.span`
font-size: 1.125rem;
font-weight: 300;
letter-spacing: 0;
line-height: 1.5rem;
margin-bottom: 10px;
margin: 14px;
`;

  export const GuestEmail = styled(FiMail)`
    color: #06f;
    margin: 5px;
  `;

  export const GuestPhone = styled(FiPhone)`
  color: #06f;
  margin: 5px;
  `;

  export const GuestInfo = styled.div`
    display: flex;
    align-items: center;
  `;

  export const GuestContact = styled.span`
    font-size: 14px;
    font-weight: 200;
    margin: 0px 0px 0px 10px;
  `;

  export const GuestContainer = styled.div`
    display: flex;
  `;

  export const PledgeSection = styled.div`
    width: 100%;
    border-top: 1px solid #dadce0;
    /* margin: 5px 5px 5px 10px; */
    /* padding: 5px 5px 5px 14px; */
  `;
  export const PledgeItem = styled.span`
    font-size: 12px;
    margin: 2px 0px 5px 12px;
    font-weight: 300;
  `;
export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px 0px 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;