import styled from 'styled-components'

type inputElements = {
    focus: boolean
}

export const SearchInputContainer = styled.div`
   display: flex;
   width: 50%;
   @media screen and (max-width: 768px) {
     width:80%;
   }

   & > input {
  width: 100%;
  border: 1px solid #b1bfca;
  border-right: none;
  padding: 5px;
  height: 30px;
  border-radius: 5px 0 0 5px;
  outline: none;
  /* color: #9DBFAF; */
   }

   & > button {
    width: 50px;
  height: 42px;
  border: 1px solid #4B59F7;
  background: #4B59F7;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
   }
`;

export const InputContainer = styled.div<inputElements>`
 display: flex;
  flex-direction: column;
  margin: 15px 0;
  position: relative;
  transition: all 0.2s ease;

  & > input {
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    background-color: transparent;
    outline: none;
    padding: 12px 3px 12px 15px;
    color: #24366b;
    font-weight: 400;
    font-size: 14px;
    transition: all 0.2s ease;
    z-index: 500;
  }
  & > label {
    color: #24366b;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    top: 15px;
    left: 15px;
    transition: all 0.2s ease;
    z-index: 500;

    ${(props:inputElements) => props.focus && `
      font-size: 13px;
      transform: translateY(-23px) translateX(-5px);
      z-index: 501;
      background: white;
      padding: 0 8px;
    `}
  }
`