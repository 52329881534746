
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiClient } from '../../Data/ApiClient';
import { Location } from '../../Data/Classes/Location';
import { TempCategory } from '../../Data/Classes/TempCat';
import { Button } from '../../theme/globalStyles';
import GlobalActionMessage from '../Common/GlobalActionMessage';
import GlobalProgressLoader from '../Common/GlobalLoader';
import { SpStep1, Step2, Step3 } from './Forms';

import { FormWrapper, SignupLoaderWrapper } from './Signup.elements';

// const proxy = require('http-proxy-middleware');



// type SignupFormProps = {
//     onFinish: () => void;
// };


const SpSignupForm:React.FC = ()=>{

    const [stepCount,setStepCount] = useState(0);
    const [spLocation,setSpLocation] = useState("");
    // const [eventDate,setEventDate] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");
    const [showLoader,setShowLoader] = useState(false);
    const [serverRes,setServerRes] = useState("");
    const [showMessage,setShowMessage] = useState(false);
    const [isError,setIsError] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [phoneNumberConfirmationCode,setPhoneNumberConfirmationCode] = useState("");
    const [password,setPassword] = useState("");
    const [confirmPassword,setConfirmPassword] = useState("");
    const [suggestedLocations,setSuggestedLocations] = useState<Location[]>([]);
    const [spCategories,setSpCategories] = useState<TempCategory[]>([]);
    const [selectedCategories,setSelectedCategories] = useState([]);
    const [spBusinessName,setSpBusinessName] = useState('');
    const history = useHistory();

    const handleSpLocationSelection = (e:any) =>{
        setSpLocation(e.value)
    }

    

    const [isVerified,setIsVerified] = useState(false);

    const [showCodeinput,setShowCodeInput] = useState(false);

    const handlePasswordChange = (val:any) => {setPassword(val)};

    const handlePasswordConfirmation = (val:any) => {setConfirmPassword(val)};

    const handlePhoneNumberChange = (val:any) =>{setPhoneNumber(val)}

    const handlenameChange = (val:any) => {setSpBusinessName(val)};
    const handleVerificationCodeChange = (val: any) =>{setPhoneNumberConfirmationCode(val)}

    const handleCategoryChangeListener = (val:any) => {
        setSelectedCategories(val);
    }

    // const handleDateChange = (val:any) =>{
    //     setEventDate(val);
    // }
    

    const handleLocationInputChange = (val:string) => {
      if(val.length > 2){
            getLocationSuggestions(val);
      }
    }


    const registerNewServiceProvider = function(spname:any,mobile:any,location:any,password:any,catz:any):void{
        setShowLoader(!showLoader);

        ApiClient.post('sp_self_register',{
            name: spname,
            mobile:mobile,
            location_id:location,
            password:password,
            categories:catz
        }).then((response) => {
            setServerRes("Registration Success, Redirecting to login ...");
            history.push('login')
            setShowMessage(true);
            setShowLoader(false);
            setIsError(false);
            // <Redirect to={"https://www.harusi.online/login"}/>
        }
        ).catch((error) => {
            setServerRes("There is an error");
            setShowMessage(true);
            setIsError(true);
        }
        );
    }


 


    const verifyOTP =function(code:any,number:any):void{
        setShowLoader(!showLoader);
        ApiClient.post('eventsp/sms_code_verify',{
            sms_code: code,
            number: number
        }).then((response) => {
            console.log(response);
            if(response.status === 200){
            setServerRes(response.data);
            setShowMessage(true);
            setIsError(false);
            setIsSuccess(true);
            setIsVerified(true);
            setShowLoader(false);
            }
        }
        ).catch((error)=>{
            setServerRes(error.response.data);
            setShowMessage(true);
            setIsError(true);
            setIsVerified(false);
        }
        );
    }

    // type locationData = {
    //     id:number;
    //     display_name:string;
    // }


    

    const getLocationSuggestions = function(val:string):void{
        setShowLoader(!showLoader);
        ApiClient.post('locations/search',{
           search:val
       }).then((response)=>{
        setShowLoader(false);
        getServiceProviderCategories();
            console.log(response)
            let allLocationsArray = response.data;
            let arrangedArray:Location[] = allLocationsArray.map((loc:any) => new Location(loc));
            setSuggestedLocations(arrangedArray);
            console.log(arrangedArray);
       }
       ).catch((error)=>{
        setShowLoader(!showLoader);
            console.log(error);
       });
    }

    const getServiceProviderCategories = ():void =>{
        ApiClient.get('service_categories/catz').then((response) => {
            let allCategoriesResponse = response.data;
            let formatedArray:TempCategory[] = allCategoriesResponse.map((cat:any) => new TempCategory(cat));
            setSpCategories(formatedArray);
        }
        ).catch((error) => {
            console.log(error);

        }
        );
    }

   

    const requestVerification = () =>{
        setShowLoader(!showLoader);
         ApiClient.post('sp_self_register/verifynumber',{
            mobile:phoneNumber
        })
        .then((response)=>{
            // alert(response);
            setShowCodeInput(!showCodeinput);
            console.log(response);
            setShowLoader(false);
            // setServerRes(response.response.message);
            setShowMessage(false);
            setIsError(false);
        })
        .catch((error)=>{
            // setShowLoader(!showLoader);
            // // alert(error);
            // // console.log("tatizo",error.response.status);
            // setServerRes(error.response.message);
            // setShowMessage(!showMessage);
            console.log("tatizo",error);

            if (error.response) {
                setShowLoader(false);
                // alert("Exception occured");
            // alert(error);
            // console.log("tatizo",error.response.status);
            setServerRes(error.response.data);
            setShowMessage(true);
            setIsError(true);
            // console.log(error.response);
              } else if (error.request) {
                // client never received a response, or request never left
                // alert("Exception occured");
              } 
           
        });
    }


    // const handleFormComplete = () =>{
    //     alert("mwisho");
    // }

    const requestCode = () => {
        if(phoneNumber.length ===10){
           requestVerification();
        }else{
            alert("Exception");
        }
            
    }



    const handleNextStep = (currentStep:number) => {
        if(currentStep === 0){
            if(spBusinessName.length > 0 && spLocation !== undefined && selectedCategories.length > 0){
                setStepCount(cur => cur+1)
                // setIsVerified(!isVerified)
            }else{
                alert("all inputs are required");
                // console.log(spBusinessName.length,spLocation !== undefined,selectedCategories.length)
            }
       
         }else if(currentStep === 1){
             if(isVerified){
                setStepCount(cur => cur+1)
             }else{
                 alert("Phone Verificarion is required")
             }
         }
    }

    const handleBackStep = () => {
        setStepCount(cur => cur-1)

    }


    const renderButton = () => {
        
        if(stepCount > 2){
            return undefined;
        }else if (stepCount === 2){
            return (
                
                <Button disabled={showLoader} onClick={e=>{
                    //@TODO register new service provider
                    // registerNewUser(eventDate,phoneNumber,eventType,password);
                    registerNewServiceProvider(spBusinessName,phoneNumber,spLocation,password,selectedCategories);
                }}>Create Account</Button>
            );
        }else if (stepCount === 1){
            return (
                <>

                {!showCodeinput &&(
                    <Button disabled={showLoader} onClick={requestCode}>Request Verification</Button>
                )}

                {showCodeinput && (
                    <Button disabled= {isVerified ? true : showLoader}  onClick={e=>{
                        verifyOTP(phoneNumberConfirmationCode,phoneNumber)
                    }}>Verify</Button>
                )}

                {isVerified && (
                    <Button onClick={e=>(handleNextStep(stepCount))} >Next </Button>
                )}

                </>
            );
        }else{
            return(
                <Button onClick={e=>(handleNextStep(stepCount))} >Next</Button>
            );
        }
    };

    const renderBackButtton = () => {
        if(stepCount === 0){
            return undefined;
        }else if(stepCount > 0 && stepCount <= 2){
            return(
                <Button onClick={handleBackStep}>Back</Button>
            );
        }

    };

  
//     const handleBackClick = () => {
//         onBack();

    return (
            <FormWrapper>
               

               {stepCount === 0 && (
                   <SpStep1 locationSelectListener={handleSpLocationSelection} categoryChangeListener={handleCategoryChangeListener} nameChangeListener = {handlenameChange} locationChangeListener = {handleLocationInputChange} LocationOptions = {suggestedLocations} CategoryOptions={spCategories}/>
                   
               )}
               {stepCount === 1 && (
                   <Step2 showPhone={showCodeinput} changeListener={handlePhoneNumberChange} verificationListener = {handleVerificationCodeChange} phoneValue = {phoneNumber} phoneConfirmation = {phoneNumberConfirmationCode}/>
                   
                )}
                {stepCount === 2 && (
                    <Step3 passwordListener={handlePasswordChange} confirmPasswordListener = {handlePasswordConfirmation} passwordValue = {password} passwordConfirmValue={confirmPassword}/>
                )}

                {showMessage && (
                    <GlobalActionMessage message={serverRes} isError = {isError} isSuccess={isSuccess}/>
                )}

                {renderBackButtton()}

                {renderButton()}
                
                <SignupLoaderWrapper>
                <GlobalProgressLoader isVisible={showLoader} width={50} height={50}/>
                </SignupLoaderWrapper>
            </FormWrapper>
        );
};



export default SpSignupForm;