import { AiOutlineGoogle } from 'react-icons/ai';
import { FaMoneyBillWave } from 'react-icons/fa';
import { GiCash, GiTakeMyMoney } from 'react-icons/gi';
import { IoIosContacts } from 'react-icons/io';
import {BiCloudDownload} from 'react-icons/bi'
import {MdAdd} from 'react-icons/md'
import styled, { css } from 'styled-components';


export const HomeSectionHolder = styled.div`

`;
export const SpEditButton = styled.button`
    margin: 10px;
    border:none;
    width: 104px;
    background-color: #e9eefb;
    border-radius: 6px;
    font-weight: 100;
    font-size: 13px;
    line-height: 15px;
    padding: 10px 2px;
    color: #06f;
    cursor: pointer;
`;

export const SpCategory = styled.span`
    background: #f1f8e9;
    margin: 5px;
    padding: 6px;
    font-size: 12px;
    color: #727ea3;
    line-height: 10px;
    border-radius: 8px;
    text-align: center;
    min-width: 50px;
    cursor: pointer;
`;

export const SpCategoryContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
export const SpDescription = styled.p`
    margin: 5px;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #727ea3;
    max-width: 666px;
`;
export const SpDescriptionContainer = styled.div`
 width: 50%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 100%;
 @media screen and (max-width: 768px) {
     width: 100%;
 }
`;

export const SpNameContainer = styled.div`
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    flex-direction: column;
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
    color: #727ea3;
    text-align: left;
    border-right: 2px dashed #EEEEEE;
    @media screen and (max-width: 768px) {
        border-bottom: 2px dashed #EEEEEE;
        border-right: none;
        width: 100%;
    }
    
`;


export const SpPackageAdd = styled(MdAdd)`
    background: #06e;
    color: #fff;
    padding: 5px;
    border-radius: 20px;
    font-size: 20px;
    cursor: pointer;
`;

export const SpPackageHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;

export const SpPackagesSection = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 500px;
    background: #fff;
    
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%);
    margin:20px;
`;

export const SpUserCard = styled.div`
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    /* padding: 15px; */
    width: 600px;
    height: 291px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%);
    margin:20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        width: 300px;
        height: 500px;
    }
`;

export const SpSectionHolder = styled.div`
    display:flex;
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-direction:column;
        justify-content:center;
        align-items:center;
       
 }
`;

export const ChartSectionHolder = styled.div`
display:flex;
flex-direction:row;
justify-content:space-around;
@media screen and (max-width: 768px) {
        flex-direction:column;
        justify-content:center;
        align-items:center;
       
 }
`;

export const TopSectionHolder = styled.div`
    width:100%;
    height: 100%;
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    padding:0px 20px 20px 20px;
    @media screen and (max-width: 768px) {
        flex-direction:column;
        justify-content:center;
        align-items:center;
        padding:10px;
 }
`;

export const TopGlistSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
`;

export const GlistButtonsSectionHolder = styled.div`
/* background: red; */
width: 100%;
display: flex;
padding: 5px;
justify-content: space-around;
flex-direction: row;
@media screen and (max-width: 768px) {
        flex-direction:row;
        justify-content:center;
        align-items:center;
    
 }
`;

export const TopFilterWrapper = styled.div`
width: 100%;
display: flex;
    justify-content: center;
`;

export const TopContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
`;



export const SelectAllContainer = styled.span`
    cursor: pointer;
    &:hover{
        color:#06E;
        }
`;

export const ContainerTitle = styled.span`
    width: 100%;
    text-align: center;
    line-height: 30px;
    font-weight: 400;
    font-size: 14px;
    color: #727ea3;
    /* margin: 20px; */
    /* border-bottom: 1px solid #dadce0; */
`;
export const TheTableContainer = styled.div`
    width: 100%;
    max-height: 50vh;
    overflow: auto;
  
`;
export const NotificationTypeContainer = styled.div`
    width: 100%;
    font-size: 15px;
    color: #727ea3;
    display: flex;
    justify-content: center;
`;

type eventTypeActive = {
    isActive :boolean;
}

export const EventTypeText = styled.span<eventTypeActive>`
    margin: 0px 6px 0px 6px ;
    color: ${(p:eventTypeActive) => p.isActive ? '#06e':'#727ea3'};
    cursor: pointer;

    width: 154px;
    background-color: #e9eefb;
    border-radius: 6px;
    border:none;
    font-weight: 400;
    font-size: 14px;
    line-height: 10px;
    padding: 14px 14px;
`;

export const NotificationTopSection = styled.div`
  width: 100%;
  max-height: 40px;
  /* border-bottom: 1px solid #dadce0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NotificationsBody = styled.div`
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* margin: 5px; */
    flex-direction: column;
    align-items: center;
    /* height: 100%; */

`;

export const NotificationCharacretCountSpan = styled.span`
     font-weight: 400;
    font-size: 12px;
    color: #727ea3;
    margin:0px;
    padding: 0px;
`;

export const NotificationsText = styled.textarea`
    width: 100%;
    min-height: 100px;
    max-width: 90%;
    margin: 15px;
    border: 1px solid #727ea3;
    border-radius: 5px;
    padding: 5px;
    font-weight: 200;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const ImportCentralContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const ImportFormartDownloadLink = styled.span`
    &:hover{
        cursor: pointer;
        color: #06E;
    }
`;

export const GoogleContactsImportContainer = styled.div`
    width: 100%;
    min-height: 50vh;
    /* display: flex;

    justify-content: center;
    align-items: center; */
`;
export const GoogleLoginBtnSpan = styled.span`

padding: 10px 10px 10px 0px;
    font-weight: 500;

`;
export const GoogleLoginBtnDiv = styled(AiOutlineGoogle)`
    margin-right: 10px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 2px;
    color:#06e;
    font-size: 20px;
`;
export const GoogleLoginBtn = styled.button`
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
`;

export const GlobalModalClose = styled.span`
    color: red;
    cursor: pointer;
    text-align: end;
    font-size: 20px;
    margin: 3px;
`

export const GlobalModalWrapper = styled.div`
     position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(15px);
`;

export const NotificationModalContainer = styled.div`
  background: #fff;
  /* max-width: 90vh; */
  min-width: 30%;
  max-height: 85vh;
  position: relative;
  border-radius: 10px;
  display: flex;
  
  flex-direction: column;
  padding: 5px;

  @media screen and (max-width: 768px) {
       
      min-height: 40%;
      min-width: 80%;
 }

`;

export const GrCloudDownloadIcon = styled(BiCloudDownload)`
    color:#06E;
    font-size: 16px;
    vertical-align: baseline;
`;

export const IoIosContactsIcon = styled(IoIosContacts)`
     color:#06e;
    font-size: 30px;
    margin-bottom: 5px;
`;

export const AiOutlineGoogleIcon = styled(AiOutlineGoogle)`
    color:#06e;
    font-size: 30px;
`;


export const BtnEventNotification = styled.button`
    width: 154px;
    background-color: #e9eefb;
    border-radius: 6px;
    border:none;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    padding: 14px 14px;
    color: #06f;
    cursor: pointer;
    margin: 5px;
`;

export const BtnDelete = styled.button`
    width: 154px;
    background-color: red;
    border-radius: 6px;
    border:none;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    padding: 14px 14px;
    color: white;
    cursor: pointer;
    margin: 5px;
`;
export const StatsSection = styled.div`
    width: 100%;
    height: 160px;
    background: #e9eefb;
    border-radius: 5px;
    margin: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(230px,1fr));
    grid-gap: 5px;
    justify-items: center;

    @media screen and (max-width: 768px) {
       
        grid-template-columns: repeat(auto-fit,minmax(90px,1fr));
  }
`;

export const SectionTitle = styled.p`
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #24366b;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        text-align: center;
  }

   
`;

export const SpProfileText = styled.p`
    margin: 10px 0px 0px 0px;
`;

export const SpProfile = styled.img`
    width: 120px;
    max-width: 100%;
    border-radius: 50%;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.06);

  :hover{
    -webkit-box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
}
  
`;

export const ListingCardContainer = styled.div`
    display:flex;
    flex-direction:column;
    width: 300px;
    height: 291px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%);
    margin:20px;
    @media screen and (max-width: 768px) {
        margin:10px;
  }
`;

export const ListItemAMount = styled.p`
     @media screen and (max-width: 768px) {
        font-size: 11px;
    }
`;

export const ListItem = styled.span`
    margin:5px;
    font-size:14px;
    font-weight:300;
    display:flex;
    justify-content: space-between;
    /* justify-content:space-around; */
    align-items:center;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;

export const ListingContents = styled.div`
    /* justify-content: space-between; */
    /* align-items: center; */
    flex-direction: column;
    display: flex;
    height: 100%;
    font-size: 12px;

`;

export const SectionCardHolder = styled.div`
    width:100%;
    height: 100%;
    /* display:flex; */
    /* flex-direction:row; */
    
`;

type cardOptions = {
    color:string;
}

export const IndividualCard = styled.div<cardOptions>`
    padding:10px;
    margin:15px;
    width:210px;
    display:flex;
    flex-direction:column;
    height: 100px;
    border-radius:3px;
    justify-content:space-between;
    ${css`
    background: ${(props: cardOptions) =>
      props.color !== undefined ? props.color : "#fff"};
  `}
  @media screen and (max-width: 768px) {
    margin:10px;
  }
`;

export const MoneyBillWave = styled(FaMoneyBillWave)`
    color: #4527a0;
    align-self: flex-end;
    margin: 10px;
    width: 25px;
    background: #fff;
    height: 25px;
    font-size: 30px;
    border-radius: 25px;
    padding: 7px;
`;

export const TakeMyMoney = styled(GiTakeMyMoney)`
    color: #f57c00;
    align-self: flex-end;
    margin: 10px;
    width: 25px;
    background: #fff;
    height: 25px;
    font-size: 30px;
    border-radius: 25px;
    padding: 7px;
`;

export const Cash = styled(GiCash)`
    color: #388e3c;
    align-self: flex-end;
    margin: 10px;
    width: 25px;
    background: #fff;
    height: 25px;
    font-size: 30px;
    border-radius: 25px;
    padding: 7px;
`;

export const IndividualCardTitle = styled.span`
    display: block;
    font-weight: 400;
    font-size: 11px;
`;

export const IndividualCardAmount = styled.span<cardOptions>`
    font-weight:bold;
    font-size:20px;
    color: ${(props: cardOptions) => props.color !== undefined ? props.color : "#fff"}
`;
