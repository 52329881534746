import { FilterClearButton, FilterComponentWrapper, FilterInput } from "./TableListing.elements";


type FilterComponentProps = {
    filterText: string;
    onFilter:(e:any)=>void;
    onClear:()=>void;
}

const FilterComponent:React.FC<FilterComponentProps> = ({ filterText, onFilter, onClear }) => (
    <FilterComponentWrapper>
      <FilterInput
        id="search"
        type="text"
        placeholder="Search.."
        value={filterText}
        onChange={onFilter}
        small={false}
      />
      <FilterClearButton onClick={onClear}>X</FilterClearButton>
    </FilterComponentWrapper>
  );
  
  export default FilterComponent;