import styled from 'styled-components';


export const CardContainer = styled.div`
 box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  transition: 0.3s;
  width: 40%;
  /* background:red; */
  margin:5px;
  flex: 0 0 21%;

    cursor: pointer;
  justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 25%;
`;

export const CardContentContainer = styled.div`
    padding:2px;
    display:flex;
    justify-content:center;
    align-items:center;
`;


export const PackageTitle = styled.h5`
    font-size:20px;
`;

export const SpBadge = styled.span`
cursor: pointer;
    margin:5px;
  /* height: 20px; */
  /* background:#4A85FF; */
  /* position: absolute;
  top: 0px;
  right: 0px; */
  color:white;
  /* 
  border-radius:5px 5px 5px 5px; */
  padding:5px; 

  display: inline-block;
  /* padding: 0 25px;
  height: 20px; */
  font-size: 13px;
  /* line-height: 50px; */
  border-radius: 25px;
  background-color:#4A85FF;
  /* background-color: #f1f1f1; */
`;

export const PackagePrice = styled.h2`
    font-size:20px;
    font-weight :500 ;
`;

export const PackageDesc = styled.p`
    text-align:center;
    margin:2px;
    padding:2px;
`;

