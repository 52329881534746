import { Photo } from "./Photo"


  export class Event{
    id: number
    event_title: string
    event_description: string
    event_address: string
    event_type: string
    mobile: string
    email: string
    status?: number
    event_date: string
    valid_through: string
    event_message: string
    event_location_ibada: string
    event_time_ibada: string
    event_location_reception: string
    event_time_reception: string
    single_minimum_rate: string
    double_minimum_rate: string
    default_pledge_amount: string
    location_id: number
    published: number
    has_stream: boolean
    colors ?: Colors
    photos ?: Photo[]
    count:number
    wallpaper? : Photo[]

    constructor (event:any){
        this.id = event.id;
        this.event_title = event.event_title;
        this.event_description = event.event_description;
        this.event_address = event.event_address;
        this.event_type = event.event_type;
        this.mobile = event.mobile;
        this.email = event.email;
        this.status = event.status;
        this.event_date = event.event_date;
        this.valid_through = event.valid_through;
        this.event_message = event.event_message;
        this.event_location_ibada = event.event_location_ibada;
        this.event_location_reception = event.event_location_reception;
        this.event_time_reception = event.event_time_reception;
        this.single_minimum_rate = event.single_minimum_rate;
        this.double_minimum_rate = event.double_minimum_rate;
        this.default_pledge_amount = event.default_pledge_amount;
        this.location_id = event.location_id;
        this.event_time_ibada = event.event_time_ibada;
        this.published = event.published;
        this.has_stream = event.has_stream;
        this.colors = event.colors;
        this.photos = event.photos !== undefined ? event.photos.map((pht:Photo)=>{return(new Photo(pht))}) : [];
        this.count = event.count;
        this.wallpaper = event.wallpaper !== undefined ? event.wallpaper.map((pht:Photo)=>{return(new Photo(pht))}) : [];
    }
   
  }



 

  


  export class Colors {
      primary: string
      secondary: string
      tertially: string
    constructor(color:any) {
        this.primary = color.primary_color;
        this.secondary = color.secondary_color;
        this.tertially = color.tertially_color;
    }
}