import React from 'react'
import { Select } from './Spinner.elements'

type SpinnerOptions = {
        title: string;
        options: string[]
        onEventTypeClick:(e:React.ChangeEvent<HTMLSelectElement>) => void;
        eventType?: string;
};




const Spinner:React.FC<SpinnerOptions> = ({title,options,onEventTypeClick,eventType}) =>{
        return(
            <Select onChange = {e=>onEventTypeClick(e)} value={eventType}>
                <option  value="" hidden>
                    {title}
        </option>

        {options.map((value,index) => {
             return (<option value={value} key={index}>{value}</option>);
        })}
         </Select>
        );
};


export default Spinner;



