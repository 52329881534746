import styled, { css } from 'styled-components';
import img from '../../assets/images/login_bg.png';
import right from '../../assets/images/bkg.png';
import mapbg from '../../assets/images/map.png';
import googleplay from '../../assets/images/googleplay.png'


export const Section = styled.div`
   /* background:red; */
   margin:0;
   padding:0;
   position:sticky;
`;


export const TopSection = styled.div`
    display:flex;
    height: 100vh;
    /* width: 100vw; */
    flex-direction:row;
    align-items:center;
    /* justify-content:center;
    align-items:stretch;
    align-content:center; */

    @media screen and (max-width: 960px) {
    flex-direction:column;
  }
 
`;

type imgSrc = {
    src?:string;
}

export const MapHolder = styled.div`
    width:80%;
    height: 40%;
    background:gray;
    background-image:url(${mapbg});
    margin:10px;
    display:flex;
    justify-content:center;
    align-items:center;
`;

export const LocationDetails = styled.p`
    text-align:center;
    width:182px;
`;

export const GooglePlayIcon = styled.a`
    width:250px;
    height: 100px;
    background:url(${googleplay});
    background-repeat: no-repeat;
    background-size: 250px 100px;
`;

export const EventDetailsHolder = styled.div`
    position: absolute;
    bottom: 180px;
    left: 100px;
    color:#fff;

    `;

export const EventHeader = styled.h1`
    font-family: 'Courgette', cursive;
`;

export const EventCaption = styled.p`

`;

export const ImageHolder = styled.div<imgSrc>`
    /* height: 100%; */
    /* background:red; */
    width:100%;
    height:100% ;
    position:relative;
    /* flex: 1; */
    ${css`
        background-image: url(${(props: imgSrc)=>props.src !== undefined ? props.src : img});
    `}
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
    background-color:rgb(255, 255, 255);
    /* position:absolute;
    z-index:4; */

    display:flex;

    @media screen and (max-width: 960px) {
        min-height: 100vh;
       
        
  }


    ::before{
    content: ""; 
    position: absolute;
    inset: 0px; 
    height: 100% ;
    background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
    opacity: 0.6;
    }
`;
    
export const ScrollableSectionHolder = styled.div`
    height: 100%;
    /* background:yellow; */
    width:70%;
    position:relative;
    overflow: auto;
    max-height: 100vh;
    /* display:flex;
    flex-direction:column; */
    justify-content:center;
    align-items:center;

    @media screen and (max-width: 960px) {
        width:100%;
        overflow:inherit;
        
  }
    /* flex: 1; */
`;

export const FirstSection = styled.div`
    /* margin:20px; */
    height: 100%;
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    
`;

export const FirstSectionData = styled.section`
background-image: url(${right});

display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center center;
    width:80%;
    height:90%;

    @media screen and (max-width: 960px) {
        width:100%;
       
        
  }

`;

export const ContactSection = styled.section`
    text-align:center;

`;

export const EventAdminContacts = styled.p`
   
`;

export const EventAdminLink = styled.a`
    color: #4A85FF;
    text-decoration:none;
`;


export const HarusiOnlineAd = styled.section`
    display:flex;
    align-content:center;
    flex-direction:column;
    align-items:center;
`;


export const AdHeader = styled.p`
    text-align:center;
    font-family: 'Courgette', cursive;
`;

export const MapHead = styled.p`
    text-align:center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #727ea3;
`;

export const AdBody = styled.p`
    width:500px;

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #727ea3;
    max-width: 389px;

    @media screen and (max-width: 960px) {
        width:400px; 
  }
`;


export const AppDownloadLinks = styled.div`
    display:flex;
    flex-direction:row;
`;

export const BasicFooter = styled.p`
    text-align:center;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #727ea3;
    display: block;
    margin-top: 16px;
`;



export const SectionHeading = styled.h1`
text-align:center;
font-family: 'Courgette', cursive;
`;



export const SecondSection = styled.section`
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const GalleryImgHolder = styled.img`
    max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;


  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {

    width: 100%;
    max-height: 75vh;
    min-width: 0;
  
}
`;

export const GallerUL = styled.ul`
  display: flex;
  flex-wrap: wrap;


  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  
    flex-direction: row;
  


}
`;



export const GalleryLi = styled.li`
    height: 40vh;
  flex-grow: 1;

  list-style-type:none;

  @media (max-aspect-ratio: 1/1) and (max-width: 480px) {



    height: auto;
    width: 100%;
  

}

  @media (max-aspect-ratio: 1/1) {
    height: 30vh;
  
}

@media (max-height: 480px) {
  
    height: 80vh;
  
}


  &:last-child{
    flex-grow: 10;
  }
`;

export const ThirdSection = styled.section`
    height: auto;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;



    /* height: 100%; */
    /* background:yellow; */
    /* width:70%; */
    position:relative;
    overflow: auto;
    max-height: 60vh;
    /* display:flex;
    flex-direction:column; */
    /* justify-content:center;
    align-items:center; */

    /* @media screen and (max-width: 960px) {
        width:100%;
        overflow:inherit;
        
  } */
`;

export const EventTitle = styled.p`
    width:200px;
    text-align:center;
    font-size:20px;
    font-family: 'Courgette', cursive;
`;

export const EventDate = styled.p`
    font-size:18px;
    font-family: 'Courgette', cursive;
    margin:0px 0px 20px 0px;

`;

export const RSVPbtn = styled.button`
    margin:20px 0px 0px 0px;
    background:white;
    border: 1px solid #4A85FF;
    /* border-radius: 5px; */
    color: #4A85FF;
    width: 166px;
    height: 40px;
    /* border: 1px solid; */
    border-radius: 10px;

    &:hover{
        color:white;
        background:#4A85FF;
        cursor: pointer;
    }
`;