import styled from 'styled-components';

type formState = {
    active: boolean;
};


export const FormWrapper = styled.div`
    width:80%;
    margin: 10px;

`;


export const SignupLoaderWrapper = styled.div`
    display:flex;
    justify-content:center;
`;


export const SectionHolder = styled.section`

        margin:10px;
    
`;




export const FormHolder = styled.div<formState>`
        display: ${(props:formState)=>props.active ? 'flex' : 'none'};
        height: 500px;
        widows: 500px;
        justify-content:center;
`;


