import styled from "styled-components";


export const StatsContainer = styled.div `
    margin: 5px;
    padding: 10px;
    width: 100px;
    height: 50px;
    display: flex;
    flex-direction: column;
    
`;

export const StatsTitle = styled.span`
    border-bottom: 1px solid #4527a0;
    width: 50%;
    /* line-height: 10px; */
    margin: 0px 0px 5px 5px;
    font-weight: 400;
    font-size: 13px;

    @media screen and (max-width: 768px) {
        font-weight: 600;
        font-size: 12px;
  }
`;

export const StatsAmount = styled.span`
    color: #4527a0;
    /* line-height: 10px; */
    margin: 1px 0px 0px 5px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-weight: 600;
        font-size: 11px;
  }
`;