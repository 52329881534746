import styled from "styled-components";
import {RiDashboardLine, RiWhatsappLine} from 'react-icons/ri';
import {BsClockHistory, BsListTask} from 'react-icons/bs';
import { GiMoneyStack } from "react-icons/gi";
import {FiSettings} from 'react-icons/fi';
import { Link } from "react-router-dom";
import {GoFileMedia} from "react-icons/go"
import {FcInvite} from 'react-icons/fc'
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import {HiUserGroup} from 'react-icons/hi';

export const SideNavLink = styled(Link)`
    text-decoration:none;
  &.active {
    color: #4A85FF;
  }
`;

export const SidebarCardContainer = styled.div`
    width:100%;
    height: 30px;
    margin:10px 5px 5px 0px;
    display:flex;
    flex-direction:row;
    align-items:center;
    /* justify-content:center; */
    color:#fff;
    font-size:14px;
    font-weight:200;
    text-align:start;
    cursor: pointer;
    /* border: 0px solid #fff;
    border-radius:0px 10px 10px 0px; */
`;


export const SidebarMenuTitle = styled.span`
     @media screen and (max-width: 768px) {
        display:none;
  }
`;



export const DashboardLine = styled(RiDashboardLine)`
  color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;
export const ListTask = styled(BsListTask)`
  color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;
export const MoneyStack = styled(GiMoneyStack)`
  color: #fff;
  margin:10px;
  width:18px;
  height: 18px;

`;

export const Invite = styled(FcInvite)`
  color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;
export const Settings = styled(FiSettings)`
  color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;

export const AiOutlineUsergroupAddIcon = styled(AiOutlineUsergroupAdd)`
   color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;

export const GroupIcon = styled(HiUserGroup)`
   color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;

export const HistoryIcon = styled(BsClockHistory)`
   color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;

export const Media = styled(GoFileMedia)`
  color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;

export const WhatsappIcon = styled(RiWhatsappLine)`
  color: #fff;
  margin:10px;
  width:18px;
  height: 18px;
`;