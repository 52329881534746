import { CardBody, CardDesc, CardTitle, RightCardContent, SpCardTextContainer, SpLeftLine, SpPackagePrice } from '../BudgetCard/BudgetCard.elements'
import { ServiceProviderCardItem } from './Style'

type packageProps = {
    title?:string;
    price?:string;
    desc?:string;
}

const SpPackage:React.FC<packageProps>=({title,price,desc})=>{
    return (
        <ServiceProviderCardItem>
             <SpLeftLine/>
             <RightCardContent>
             <CardTitle>
                 {title}
            </CardTitle>
            <CardBody>
            <SpCardTextContainer>
                <SpPackagePrice>
                    {price}
                </SpPackagePrice>
                <CardDesc>
                    {desc}
                </CardDesc>
            </SpCardTextContainer>
            </CardBody>
             </RightCardContent>
        </ServiceProviderCardItem>
    )
}

export default SpPackage
