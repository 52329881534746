
import React from 'react';
// import { AiOutlineSearch } from 'react-icons/ai';
import { InputContainer } from './Textfield.elemets';




type inputTypes = "text"|"number"|"email"|"date"|"password";
type inputData = {
    value? : string;
    type : inputTypes;
    label: String;
    onChange : (str:any) => void;
    // onBlur?: () => void;
    isError? : boolean;
    errorMessage?: string;
    holder?: string;
    maxInput?: number;
    isDisabled?:boolean;
    isRequired?:boolean;
};


const InputField:React.FC<inputData> = ({isRequired,type,onChange,label,value,isError,errorMessage,holder,maxInput,isDisabled}) =>{
    const [focused, setFocused] = React.useState(false)
    // const [disabled,setisDisabled] = React.useState(false);
   
    const handleOnBlur = () => {
        setFocused(false)
        // onBlur
      }
    const handleOnChange = (val: any) => {
        // validateValue(val)
        onChange(val)
      }

      const handleOnFocus = () => {
        setFocused(true)
       
        // onFocus()
      }

      const renderLabel = () => {
        // setisDisabled(!isDisabled);
        if (label) {
          // if we have an error
          if (isError) {
            return <label>{ errorMessage }</label>
          }
    
          return <label>{ label }</label>      
        }
        return null
      }

      const isFocused = focused || String(value).length > 1 || type === "date"
    return(
        <InputContainer focus={isFocused}>
            {renderLabel()}
        <input type={type} required={isRequired} disabled={isDisabled} min={type === 'date' ? new Date().toISOString().slice(0,10):""} value = {value}   onChange = {e => handleOnChange(e.target.value)} placeholder={holder} onFocus={handleOnFocus} onBlur={handleOnBlur} maxLength = {maxInput}/>
        </InputContainer>
    );
};



export default InputField;