import { GiPencil } from 'react-icons/gi';
import { IoMdContact } from 'react-icons/io';
import styled from 'styled-components';

export const PaymentsTable = styled.table`
  width: 100%;
`;
export const WholeTableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const WaalikwaTableContainer = styled.div`
   width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotificationTable = styled.table`
   border-collapse: collapse;
    margin: 25px 0;
    font-size: 14px;
    font-weight: 300;
    min-width: 500px;
    max-height: 100px;
    width: 100%;

    @media screen and (max-width: 768px) {
      min-width: 0px;
    max-height: 0px;
    }
    
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
`;

export const NotificationTableHeader = styled.thead`
    /* color: #ffffff; */
    text-align: left;
`;

export const NotificationTableBody = styled.tbody`
  
`;

export const NotificationTableTh = styled.th`
  padding: 12px 15px;
`;

export const NotificationTableTd = styled.td`
  padding: 12px 15px;

`;

export const CardTypeRadio = styled.input`

`;

export const CardTypeRadioLabel = styled.label`

`;

export const GroupListItemContainer = styled.div<{isSelected?:boolean}>`
  height: 35px;
  margin: 5px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  background: ${props=>props.isSelected ? '#e9eefb' : undefined};
`;

export const NotificationTableTr = styled.tr<{isSelected?:boolean}>`
   /* color: #ffffff; */
    text-align: left;
    cursor: pointer;
    background: ${props=>props.isSelected ? '#e9eefb' : undefined};
`;

type FilterInputProps = {
  small: boolean
}

export const FilterComponentWrapper = styled.div`
 display: flex;
 flex-direction: row;
`


export const FilterInput = styled.input<FilterInputProps>`
  size: ${props=> props.small ? 5 : undefined};
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;
`;

export const FilterClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MaintTableOpening = styled.table`
  
   width: 100%;
`;

export const TableHeading = styled.thead`
    /* background: #fff; */
    border-bottom: 1px solid rgba(0,0,0,0.12);
 

    box-sizing: border-box;
    cursor: default;
    display: table;
    height: 50px;
    margin-bottom: 8px;
    overflow: hidden;

    width: 100%;
    font-weight:200;
`;

export const Th = styled.th<{can_hide?:boolean}>`
  /* text-align: center; */
  font-weight: 500;
  font-size: 12px;


  @media screen and (max-width: 768px) {
        /* text-align: center; */
        ${props=>props.can_hide ? 'display:none' : undefined}
  }
`;

export const Tr = styled.tr`
   cursor: pointer;

   :nth-of-type(even){
    background: rgb(233, 238, 251);
  }

`;

export const EditBg = styled.div`
  background: #e9eefb;
  text-align: center;
  border-radius: 5px;
  color: #06E;
  font-size: 20px;
`;

export const EditIcon = styled(GiPencil)`
  color:#06E;
  font-size: 14px;
`;

export const Td = styled.td<{can_hide?:boolean}>`
padding:10px;

  /* text-align: center; */
  /* vertical-align:middle; */
  font-weight: 200;
  font-size: 14px;
  /* border-bottom: 0.1px solid rgba(0,0,0,0.1); */
  display:table-cell;

  

  @media screen and (max-width: 768px) {
        /* text-align: center; */

        ${props=>props.can_hide ? 'display:none' : undefined}
  }
`;

export const Tbody = styled.tbody`
    display: table;
    width: 100%;
    table-layout: fixed;
    /* justify-content: center; */
    table-layout: auto;
`;

export const ContactCard = styled(IoMdContact)`
   color: #06f;
    /* margin: 10px; */
    margin: 0px 5px 0px 5px;
    height: 35px;
    width: 35px;
    vertical-align: middle;
`;

export const ContactCardModal = styled(IoMdContact)`
   color: #06f;
    /* margin: 10px; */
    margin:10px;
    height: 60px;
    width: 60px;
    vertical-align: middle;
`;


export const NewButtons = styled.button`
  cursor: pointer;
    height: 35px;
    width: 100px;
    border: none;
    margin: 10px;
    background: #06e;
    color: white;
    border-radius: 10px;
`;
export const SendButton = styled.button`
    cursor: pointer;
    height: 35px;
    width: 100px;
    border: none;
    color: #fff;
    position: relative;
    background-color: #06f;
    overflow: hidden;
    z-index: 1;
    border-radius: 6px;
    align-self: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    box-shadow: none;
    position: absolute;
    bottom:20px;
	  /* right:40px; */
`;

export const Label =styled.label``;

export const TheTableContainer = styled.div`
    width: 100%;
    max-height: 50vh;
    overflow: auto;
  
`;

export const DeleteButton=styled.button`
    cursor: pointer;
    height: 35px;
    width: 100px;
    border: none;
    color: #fff;
    position: relative;
    background-color: red;
    overflow: hidden;
    z-index: 1;
    border-radius: 6px;
    align-self: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    box-shadow: none;
    position: absolute;
    bottom:20px;
    /* right:40px; */
`;