import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';



export const Nav = styled.nav`
  margin-left:0;
  margin-right:0;
  height: 72px;
  display: flex;
  z-index: 999;
  /* padding:0 1rem; */
  justify-content:space-between;
  align-items:center;
  flex-wrap: wrap;
  background: white;
  /* position: absolute; */
  /* top: 0;
  left: 10px;
  right: 10px; */
`;

export const AppLogo = styled.img`
    width: 54px;
    height: 54px;
    
`;


export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-size:14px;
  cursor: pointer;
  &.active {
    color: #4A85FF;
  }
    font-weight: 600;
    text-align: center;
    color: #24366b;
    cursor: pointer;
`;

type Click = {
  click? : boolean;
}

export const NavMenu = styled.div<Click>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type:none;
  text-align:center;
  width: 60%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction:column;
    width:100%;
    height:20%;
    position:absolute;
    top:80px;
    left: ${props=>(props.click ? '0' : '-100%' )};
    /* {($click) ? 0 : '-100%'}; */
    opacity: 1;
    transition:all 0.5s ease;
    background: white;
    z-index: 10;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #000;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
