import React from 'react'
import { UserEvent } from '../../Data/Classes/UserEvent';
import { SelectInput } from './Select.elements'


type SelectInputOptions = {
        events:UserEvent[]|undefined;
        inputSelectListener:(e:React.ChangeEvent<HTMLSelectElement>) => void;
        eventAddress:string;
};

const Selectinput:React.FC<SelectInputOptions> = ({events,inputSelectListener,eventAddress}) =>{
    return(
        <SelectInput onChange = {e=>inputSelectListener(e)} value={eventAddress}>
                {
                events?.map((event,index) => {
                    return(
                        <option value={event.event_address} key={index}>{event.event_title}</option>
                    );
                })}
        </SelectInput>
    );
}






export default Selectinput;

