import { Category } from "./Category"
import { Package } from "./Package"
import { Photo } from "./Photo"


export class ServiceProvider {
    id: number
    name: string
    category_id: number
    mobile: string
    email: string
    website: string
    description: string
    location: string
    district_id: number
    date_created: string
    approved_date: string
    approved_by: number
    status: number
    user_id: number
    claimable: number
    claimed: number
    location_id: number
    published: number
    wallpaper?: Photo
    categories?: Category[]
    packages? : Package[]
    photos?:Photo[]

    constructor (serviceProvider:any){
        this.id  = serviceProvider.id !== undefined ? serviceProvider.id : undefined;
        this.wallpaper  = serviceProvider.wallpaper !== null ? new Photo(serviceProvider.wallpaper) : undefined;
        this.categories  = serviceProvider.categories !== undefined ? serviceProvider.categories.map((cat:Category)=>{return(new Category(cat))}) : undefined;
        this.name  = serviceProvider.name;
        this.category_id  = serviceProvider.category_id;
        this.mobile  = serviceProvider.mobile;
        this.email  = serviceProvider.email;
        this.website  = serviceProvider.website;
        this.description  = serviceProvider.description;
        this.location  = serviceProvider.location;
        this.district_id  = serviceProvider.district_id;
        this.date_created  = serviceProvider.date_created;
        this.approved_date  = serviceProvider.approved_date;
        this.approved_by  = serviceProvider.approved_by;
        this.status  = serviceProvider.status;
        this.user_id  = serviceProvider.user_id;
        this.claimable  = serviceProvider.claimable;
        this.claimed  = serviceProvider.claimed;
        this.location_id  = serviceProvider.location_id;
        this.published  = serviceProvider.published;
        this.packages = serviceProvider.packages !== undefined ? serviceProvider.packages.map((pack:Package)=>{return(new Package(pack))}) : [];
        this.photos = serviceProvider.photos !== undefined ? serviceProvider.photos.map((photo:Photo)=>{return(new Photo(photo))}) : [];
        
    }
}

