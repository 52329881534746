import {  CTA, DivHolder, HeadLine, HeroImg, LeftDiv, RightDiv, Subtitle } from './Hero.elements'
import img from '../../assets/images/hero_img.svg';
import { useHistory } from 'react-router-dom';
import { CTABTN } from '../../theme/globalStyles';




function Hero() {
  const history = useHistory();
//   const [index, setIndex] = useState(0);
//   const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
//   const LeftDivContents = [<>
//     <HeadLine>
//        Panga & Ratibu Shughuli yako, BURE!
//        <Subtitle>
//        Harusi online gives you a free & hastle less experience while planning and managing your event, with live streaming, budget management,pledging and many more.
// We also provide a platform to link service providers with event planners such as MC`s,DJ`s,Catering services and etc. Want to get listed register as a service provider.
//       </Subtitle>
//      <CTA>
//      Sajili  
//      </CTA>
//      <CTABTN onClick={()=>{history.push('register/event')}}>
//         Shughuli
//     </CTABTN>
   
      

//      <CTABTN onClick={()=>{
//         history.push('register/service')
//       }}>
//           Mtoa huduma
//       </CTABTN>
    
//       </HeadLine> 
//       </> ];

  // const nyengine = [ <LeftDivContentContainer> item1  </LeftDivContentContainer>, <LeftDivContentContainer> item2 </LeftDivContentContainer>];
    // const [isSignupModalOpen, setSignupModalOpen] = useState(false);
  // const [stepCount,setStepCount] = useState(0);

  // const forms = [<Step1/>,<Step2/>,<Step3/>]

  // // const items = [1,2,3,4,5];
  // function toggleModal() {
  //   setSignupModalOpen(!isSignupModalOpen);

  // }

  // const next = ()=>{
  //   //  if(stepCount !== items.length-1){
  //   //    setStepCount(stepCount+1);
  //      console.log(">>form finished");
  //   //  }
  // }
  // const back = ()=>{
  //   // if(stepCount !== 0){
  //   //   setStepCount(stepCount-1);
  //     console.log("back",items[stepCount]);
  //   // }
  //   }
 
  // const changeModalState = (state : boolean) => () => setSignupModalOpen(state);
  // function resetTimeout() {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  // }


  // useEffect(() => {
  //   resetTimeout();
  //   timeoutRef.current = setTimeout(
  //     () =>
  //       setIndex((prevIndex) =>
  //         prevIndex === LeftDivContents.length - 1 ? 0 : prevIndex + 1
  //       ),
  //     2500
  //   );

  //   return () => {
  //    resetTimeout();
  //   }
  // }, [LeftDivContents.length, index])
    return (
       
         
//             {/* <LeftDiv>
//                 <HeadLine>
//                 Panga & Ratibu Shughuli yako, BURE!
//                 <Subtitle>
//                 Harusi online gives you a free & hastle less experience while planning and managing your event, with live streaming, budget management,pledging and many more.
// We also provide a platform to link service providers with event planners such as MC`s,DJ`s,Catering services and etc. Want to get listed register as a service provider.
//                 </Subtitle>
//                 </HeadLine>
              
//             </LeftDiv>
//             <RightDiv>
//                 <HeroImg src={img}/>
//             </RightDiv> */}

            <DivHolder>
                
              <LeftDiv>
                 <HeadLine>
                 Panga & Ratibu Shughuli yako, BURE! 
                 {/* <Subtitle>
                 Harusi online gives you a free & hastle less experience while planning and managing your event, with live streaming, budget management,pledging and many more.
 We also provide a platform to link service providers with event planners such as MC`s,DJ`s,Catering services and etc. Want to get listed register as a service provider.
                 </Subtitle> */}

                  <Subtitle>
                 Harusi online ni mfumo unaorahisisha kupangilia & kuratibu shughuli yako bure kupitia huduma kama kutuma taarifa, kupanga bajeti, kupokea na kurekodi taarifa za fedha, kukukutanisha na watoa huduma
              na huduma nyingine nyingi.. 
              Vilevile tunawakutanisha wanakamati wa shughuli na watoa huduma kama ukumbi, chakula nk..
                 </Subtitle>
                 <CTA>
      Sajili  
      </CTA>
      <CTABTN onClick={()=>{history.push('register/event')}}>
         Shughuli
     </CTABTN>
   
      

      <CTABTN onClick={()=>{
         history.push('register/service')
       }}>
           Mtoa huduma
       </CTABTN>
                 </HeadLine>
              
            </LeftDiv>

              <RightDiv>
              <HeroImg src={img}/>
              
              </RightDiv>

            

              {/* <Modal showModal={isSignupModalOpen} setShowModal={toggleModal} modalBody = { <SignupForm />}/> */}
             
                
            </DivHolder>

     
            
       
    )
};
export default Hero
