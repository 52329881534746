import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineMinusCircle } from "react-icons/ai";
import styled from "styled-components";

export const ChipContainer = styled.div`
   display: inline-flex;
    flex-direction: row;
    background-color: #e9eefb;
    border: none;
    cursor: default;
    height: 28px;
    outline: none;
    padding: 0;
    font-size: 14px;
    color: #333333;
    font-family:"Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    margin: 3px;
`;

export const ChipTitle = styled.div`
     cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
`;

export const ChipIcon = styled.div`

`;

export const CrossIcon = styled(AiOutlineCloseCircle)`
color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
  fill: currentColor;
    width: 0.7em;
    height: 0.7em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
    :hover{
    color: #ef5350;
    }
`;

export const CheckIcon = styled(AiOutlineCheckCircle)`
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
  fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
`;

export const MinusIcon = styled(AiOutlineMinusCircle)`
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
  fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
`;