import { FiEye, FiEyeOff } from "react-icons/fi";
import { GiMailbox } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import { IoSadOutline } from "react-icons/io5";
import Select from 'react-select';

import styled from "styled-components";

export const PageWrapper = styled.div`
    height: 100vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`;

export const OpenEye = styled(FiEye)`

`;

export const CloseEye = styled(FiEyeOff)`

`;

export const CentralContainer = styled.div`
    height: 389px;
    width: 389px;
    display:flex;
    flex-direction:column;
    /* justify-content:center; */
`;


export const CentralLoginLogo = styled.img`
    width:80px;
    height: 80px;
    margin:10px;
`;

export const SadIcon = styled(IoSadOutline)`
 color: #06E;
    font-size: 50px;
    text-align: center;
    width: 100%;
`;

export const MailBoxIcon = styled(GiMailbox)`
    color: #06E;
    font-size: 50px;
    text-align: center;
    width: 100%;
`;

export const CheckEmailHeader = styled.h2`
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 12px;
    color: #24366b;
`;

export const CentralLoginHeaderMain = styled.h2`
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 12px;
    color: #24366b;
`;

export const BackIcon = styled(IoIosArrowBack)`
 font-weight: 400;
 font-size: 12px;
 color: #727ea3;
 line-height: 17px;
 text-align: center;
 cursor: pointer;
`;



export const HeaderSubtitle = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #727ea3;
    max-width: 398px;
    margin-bottom: 31px;
`;

export const CheckMailSubtitle = styled.span`
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #727ea3;
    max-width: 398px;
    margin-bottom: 31px;
`;

export const GobackFrom404 = styled.p`
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 17px;
    color: #06E;
    cursor: pointer;
`;

export const InputPlaceholder = styled.span`

    /* color: #db3838; */
    font-weight: 100;
    font-size: 12px;
    margin: 6px;
    transition: all .5s ease-in-out;
    -webkit-transform-origin: top;
    transform-origin: top;
`;


export const InputUnit = styled.div`
position:relative;
box-sizing:border-box;
margin:10px 0px 0px 0px; 
width: 100%;
`;

export const SelectSpinner = styled(Select)`
border-radius: 6px;
border: 1px solid #e9eef7;
background-color: #fcfcfd;
    margin: 0;
    font-variant: tabular-nums;
    font-feature-settings: "tnum","tnum","tnum";
    color: #24366b;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5715;
    width: 100%;
    min-width: 0;
    
`;

export const PassWrapper =  styled.div`
position: relative;
  display: flex;
`;

export const EyeWrapper = styled.i`
  position: absolute;
  top: 38%;
  right: 2%;

  &:hover{
    color: #06e;
  cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-around;
`;
export const AppTextArea = styled.textarea`
    width: 90%;
`;
export const InputLabel = styled.div`
margin: 5px;
max-width: 440px;
    font-size: 14px;
    font-weight: 400;
    color: #727ea3;
`;
export const AppInputHolder = styled.div`
display: flex;
align-items: flex-start;
flex-direction: column;
width: 100%;
`;

export const LocationSpinnerHolder = styled.div`
    display: flex;
align-items: flex-start;
flex-direction: column;
width: 90%;
justify-content: center;

`;

export const Switcher = styled.input`
  
`;


export const AppFormInput = styled.input`
    transform-origin: top;
    border-radius: 6px;
    border: 1px solid #e9eef7;
    background-color: #fcfcfd;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum","tnum","tnum";
    display: inline-block;
    width: 80%;
    height: 15px;
    min-width: 0;
    padding: 15px 25px;
    background-image: none;
    outline: none;
    box-shadow: none;
    transition: all .3s ease-in-out;
    color: #24366b;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5715;
    margin: 5px;
`;

export const FormInputSelect = styled.select`
      transform-origin: top;
    border-radius: 6px;
    border: 1px solid #e9eef7;
    background-color: #fcfcfd;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum","tnum","tnum";
    display: inline-block;
    width: 94%;
    height: 30px;
    min-width: 0;
    /* padding: 15px 25px; */
    background-image: none;
    outline: none;
    box-shadow: none;
    transition: all .3s ease-in-out;
    color: #24366b;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5715;
    margin: 5px;
`;
export const SessionChange = styled.div`
text-align:center;
margin-top: 20px;
font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #727ea3;
`;
export const SeeionLink = styled.a`
margin-left: 3px;
    color: #06f;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
`;
type prop = {
    error?:boolean;
}
export const FormInput = styled.input<prop>`
    transform-origin: top;
    border-radius: 6px;
    border: 1px solid;
    border-color: ${props => props.error ? "#db3838" : "#e9eef7"};;
    background-color: #fcfcfd;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum","tnum","tnum";
    display: inline-block;
    width: 90%;
    min-width: 0;
    padding: 15px;
    background-image: none;
    outline: none;
    box-shadow: none;
    transition: all .3s ease-in-out;
    color: #24366b;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5715;
`;


export const FormSuccessText = styled.p`
    position: absolute;
    color: #43a047;
    font-size: 12px;
    margin: 6px 0 -18px;
    transition: all .5s ease-in-out;
    -webkit-transform-origin: top;
    transform-origin: top;
`;

export const FormErrorText = styled.p`
    position: absolute;
    color: #db3838;
    transition: all .5s ease-in-out;
    -webkit-transform-origin: top;
    transform-origin: top;
    font-size: 12px;
    margin: 6px 0 -18px;
    
   
`;

export const ForgetPasswordContainer = styled.div`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    margin-top: 14px;
`;

export const ForgetPasswordLink = styled.a`
    color: #06f;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration:none;
    cursor: pointer;
`;

export const AppGlobalButton =styled.button`
     height: 35px;
    border: none;
    color: #fff;
    position: relative;
    background-color: #06f;
    overflow: hidden;
    z-index: 1;
    border-radius: 6px;
    width:40%;
    cursor: pointer;
    /* margin:20px; */
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin-bottom: 10px;
`;

export const AppGlobalDeleteButton =styled.button`
     height: 30px;
    border: none;
    color: #fff;
    position: relative;
    background-color: #ff5252;
    overflow: hidden;
    z-index: 1;
    border-radius: 6px;
    width:40%;
    cursor: pointer;
    /* margin:20px; */
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin-bottom: 10px;
`;

export const AppSuccessButton = styled.button`
    height: 40px;
    border: none;
    color: #fff;
    position: relative;
    background-color: #06f;
    overflow: hidden;
    z-index: 1;
    border-radius: 6px;
    width:90%;
    margin:20px 0px 0px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
`;


export const FormLoginButton = styled.button`
    height: 50px;
    border: none;
    color: #fff;
    background-color: #06f;
    overflow: hidden;
    z-index: 1;
    border-radius: 6px;
    width:100%;
    margin:20px 0px 0px 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    box-shadow: none;
    cursor: pointer;

    :disabled{
        cursor: not-allowed;
  pointer-events: all !important;
    }
`;