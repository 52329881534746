
import { Link } from "react-router-dom";
import styled from "styled-components";
import wave from '../../assets/images/wave.svg';

export const FooterWrapper = styled.div`
  /* padding: 4rem 0 2rem 0; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  /* background:url(${wave}); */
  /* background-repeat: no-repeat;
  background-size: auto; */
  height:15vh;
  /* background-position:bottom;
  position:absolute; */
  bottom:auto;
  width:100%;
  display: flex;

  /* padding: 10px; */
  /* padding: 5px; */
  @media screen and (max-width: 768px) {
    /* height: 24vh; */
    
  }
  @media screen and (max-width: 901px) {
    /* height: 29vh; */
    
  }

  @media screen and (min-width: 901px) {
    /* height: 32vh; */
    
  }

  @media screen and (min-width: 975px) {
    /* height: 42vh; */
    
  }


  `;

export const FooterContainer = styled.div`
height: 100%;
width:100%;
  z-index: 1;
  margin-right: auto;
  margin-left: auto;
  /* background: yellow; */
  align-items: center;
  justify-content: center;
  /* padding-right: 50px;
  padding-left: 50px; */
  display:flex;
  @media screen and (max-width: 991px) {
    /* padding-right: 30px;
    padding-left: 30px; */
  }
`;


export const FooterBottom = styled.div`
width:100%;
align-self: flex-end;
justify-content:space-between;
align-content:center;
display:flex;
align-items:center;
`;

export const SocialIcon = styled.div`
background:yellow;
opacity: 0.1;
border-radius:10px;
color:white;
`;

export const SocialHolder = styled.div`
/* background: red; */
padding: 10px;
width:104px;
display:flex;
justify-content: space-evenly;
align-items: center;

`;

export const SocialMedia = styled.section`
max-width: 1000px;
width: 100%;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;

export const WebsiteRights = styled.small`
margin:20px;
font-size: 12px;
    line-height: 20px;
    color: #727ea3;
    display: block;
    margin-top: 16px;
    text-decoration:none;
    font-style:normal ;

    > a {
      font-size: 12px;
    color: #727ea3;
    text-decoration:none;
    font-style:normal ;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const SocialIconLink = styled.a`
  color: #06e;
  /* font-size: 12px; */
    /* line-height: 20px; */
    /* color: #727ea3; */
    /* display: block; */
    padding: 5px;
   
    /* margin-top: 16px; */
    border-radius: 0px;
    font-size: 20px;
`;