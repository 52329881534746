import Loader from "react-loader-spinner";

type LoaderProps = {
    isVisible:boolean;
    width?: number ;
    height?:number;
}




const GlobalProgressLoader:React.FC<LoaderProps> =({isVisible,width,height})=>{
    return(
        <Loader
        type="Bars"
        color="#4B59F7"
        height={width !== undefined? width : 100}
        width={height !== undefined? height : 100}
        visible={isVisible}
      />
    );
}

// const GlobalButtonProgressLoader:React.FC<LoaderProps> =({isVisible,width,height})=>{
//     return(
//         <Loader
//         type="Bars"
//         color="#fff"
//         height={width !== undefined? width : 100}
//         width={height !== undefined? height : 100}
//         visible={isVisible}
//       />
//     );
// }


export default GlobalProgressLoader;