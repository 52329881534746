import React from 'react'
import { ChipContainer, ChipIcon, ChipTitle, CrossIcon } from './style';


type Props = {
    title: string;
    id: number;
    iconClickAction: (id:number)=>void;
}

export const ContactGroupChip:React.FC<Props> = ({title,iconClickAction,id})=>{
    return(
        <ChipContainer>
            <ChipTitle>{title}</ChipTitle>
            <ChipIcon onClick={()=>iconClickAction(id)}><CrossIcon/></ChipIcon>
        </ChipContainer>
    );
}