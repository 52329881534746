import styled from 'styled-components';

export const ProgressChartHolder = styled.div`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    height: 291px;
    background: #fff;
    display: flex;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 2%);
    margin:20px;
    @media screen and (max-width: 768px) {
        margin:10px;
  }
`;

export const ProgressHolderSM = styled.div`
     /* background: red; */
     width:100%;
     height:100%;
     display:flex;
     justify-content:center;
     align-items:center;
`;

export const ChartTitle = styled.span`
    width:100%;
    text-align:center;
    margin: 5px 0px 0px 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgb(36, 54, 107);

`;